/* mixins */
/* Custom mixins */
/* inspired by bootstrap */
.tags_block .block_content {
  overflow: hidden; }
  .tags_block .block_content a {
    display: inline-block;
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
    padding: 4px 9px 5px 9px;
    border: 1px solid #d6d4d4;
    float: left;
    margin: 0 3px 3px 0; }
    .tags_block .block_content a:hover {
      color: #333;
      background: #f6f6f6; }
